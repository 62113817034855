import React, { useEffect, useState } from 'react';
import {
  Avatar, Card, CardContent, CardHeader,
} from '@mui/material';
import { useUsername } from './SubscribersPage';
import CheckItems from './CheckItems';
import ReplyItems from './ReplyItems';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { fetchCheckItems } from '../../features/radius/check-items';
import { fetchReplyItems } from '../../features/radius/reply-items';

const Subscriber = () => {
  const { username } = useUsername();
  if (username === undefined) return null;

  const checkItemsState = useAppSelector(
    (state: RootState) => state.checkItems,
  );
  const replyItemsState = useAppSelector(
    (state: RootState) => state.replyItems,
  );
  const dispatch = useAppDispatch();
  const [checkItems, setCheckItems] = useState(checkItemsState.value);
  const [replyItems, setReplyItems] = useState(replyItemsState.value);

  useEffect(() => {
    setCheckItems(checkItemsState.value);
    setReplyItems(replyItemsState.value);
  }, [checkItemsState.value, replyItemsState.value]);

  useEffect(() => {
    if (username !== '') {
      dispatch(fetchCheckItems({ username }));
      dispatch(fetchReplyItems({ username }));
    }
  }, [username]);

  if (replyItems.length === 0 && checkItems.length === 0) {
    return (
      <Card>
        <CardHeader title="Not found" />
        <CardContent>
          No data for subscriber with username
          {' '}
          {username}
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        component="div"
        avatar={<Avatar>{username.charAt(0).toUpperCase()}</Avatar>}
        title={username}
      />
      <CardContent>
        <CheckItems username={username} checkItems={checkItems} />
        <ReplyItems replyItems={replyItems} />
      </CardContent>
    </Card>
  );
};

export default Subscriber;
