import React, { useState, useEffect } from 'react';
import {
  Link, Outlet, useParams, useLocation, useOutletContext,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Fab from '@mui/material/Fab';
import Toolbar from '@mui/material/Toolbar';
import PersonAddIcon from '@mui/icons-material/PersonAddAlt1';
import DownloadIcon from '@mui/icons-material/Download';
import PersonIcon from '@mui/icons-material/Person';
import SubscriberForm from './SubscriberForm';
import Breadcrumbs from './Breadcrumbs';
import DuplicatesListItem from './DuplicatesListItem';

type UsernameContextType = { username: string, filter: string };
type FilterContextType = { filter: string };

const SubscribersPage = () => {
  const { username: usernameParam } = useParams();
  const filterParam = new URLSearchParams(useLocation().search).get('filter');
  const [filter, setFilter] = useState<string|undefined>('');
  const [username, setUsername] = useState<string|undefined>('');
  useEffect(() => {
    setUsername(usernameParam);
  }, [usernameParam]);
  useEffect(() => {
    if (filterParam !== null) {
      setFilter(`${filterParam}`);
    }
  }, [filterParam]);

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Drawer
        anchor="left"
        variant="permanent"
        color="secondary"
        sx={{
          width: '350px',
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: '350px', boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Divider />
        <List sx={{ height: '100%' }}>
          <ListItem>
            <ListItemButton
              component={Link}
              to="/dashboard/subscribers/"
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="All subscribers" />
            </ListItemButton>
          </ListItem>
          <DuplicatesListItem />
          <Divider />
          <ListItem>
            <ListItemButton
              component={Link}
              target="_blank"
              to="/api/v1/admin/freeradius/backup/radcheck"
            >
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary="Download radcheck" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              target="_blank"
              to="/api/v1/admin/freeradius/backup/radreply"
            >
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary="Download radreply" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <Fab
            size="large"
            color="primary"
            variant="extended"
            aria-label="create subscriber"
            component={Link}
            to="?creating=true"
            sx={{
              position: 'absolute',
              bottom: '50px',
              right: '50px',
              left: '50px',
            }}
          >
            <PersonAddIcon sx={{ pr: 1 }} />
            New Subscriber
          </Fab>
        </List>
      </Drawer>
      <Box sx={{
        height: '100%', flexGrow: 1, p: 3,
      }}
      >
        <Toolbar />
        <Breadcrumbs username={username} />
        <Outlet context={{ username, filter }} />
        <SubscriberForm />
      </Box>
    </Box>

  );
};

export function useUsername() {
  return useOutletContext<UsernameContextType>();
}

export function useFilter() {
  return useOutletContext<FilterContextType>();
}

export default SubscribersPage;
