interface Tariff {
  id: number;
  value: string;
  name: string;
  upstream?: string;
}

const Tariffs: Array<Tariff> = [
  { id: 1, value: '5MB', name: '5/5 Mbps' },
  {
    id: 2, value: '25MB', name: '25/5 Mbps', upstream: '5MB',
  },
  { id: 3, value: '50MB', name: '50/50 Mbps' },
  {
    id: 4, value: '100MB', name: '100/40 Mbps', upstream: '40MB',
  },
  { id: 5, value: '100MB', name: '100/100 Mbps' },
  { id: 6, value: '150MB', name: '150/150 Mbps' },
  {
    id: 7, value: '200MB', name: '200/40 Mbps', upstream: '40MB',
  },
  { id: 8, value: '200MB', name: '200/200 Mbps' },
  { id: 9, value: '250MB', name: '250/250 Mbps' },
  { id: 10, value: '350MB', name: '350/350 Mbps' },
  { id: 11, value: '400MB', name: '400/400 Mbps' },
  { id: 12, value: '500MB', name: '500/500 Mbps' },
  { id: 13, value: '1000MB', name: '1000/1000 Mbps' },
];

export { Tariffs };
