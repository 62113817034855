import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';

export interface CheckItem {
  id: number;
  username: string;
  attribute: string;
  value: string;
  op: string;
}

export interface CheckItemUpdate {
  value?: string,
  op?: string,
}

export interface checkItemsState {
  value: Array<CheckItem>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: checkItemsState = {
  value: [],
  status: 'idle',
};

export const fetchCheckItems = createAsyncThunk(
  'radius/fetchCheckItems',
  async ({ username }: {username: string}, { rejectWithValue }) => {
    const filter = `filter=username=${username}`;
    let response;
    try {
      response = await Client
        .get(`/api/v1/admin/freeradius/check_items/search?${filter}`);
    } catch (e) {
      return rejectWithValue(e);
    }
    response.data.items.map((x: CheckItem) => x);
    return response.data.items;
  },
);

export const updateCheckItem = createAsyncThunk(
  'radius/updateCheckItem',
  async (
    { id, checkItem }: { id: number, checkItem: CheckItemUpdate },
    { getState, rejectWithValue },
  ) => {
    let response;
    try {
      response = await Client
        .patch(`/api/v1/admin/freeradius/check_items/${id}`, {
          item: checkItem,
        });
    } catch (e) {
      return rejectWithValue(e);
    }
    const { checkItems } = getState() as { checkItems: Array<CheckItem> };
    const updatedItem = response.data.item;

    return checkItems.map((item) => {
      if (item.id === updatedItem.id) {
        return updatedItem;
      }
      return item;
    });
  },
);

export const checkItemsSlice = createSlice({
  name: 'checkItems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCheckItems.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchCheckItems.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateCheckItem.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCheckItem.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(updateCheckItem.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default checkItemsSlice.reducer;
