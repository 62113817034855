import React, { useState, useEffect, createRef } from 'react';
import {
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { ItemEditor } from './Item';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  ReplyItem,
  fetchReplyItems,
  updateReplyItem,
} from '../../features/radius/reply-items';
import { RootState } from '../../app/store';
import { Tariffs } from './tariff';

export interface ReplyItemProps {
  replyItems: Array<ReplyItem>;
}

interface IpAddressProps {
  item: ReplyItem;
}

const IpAddressField = (props: IpAddressProps) => {
  const { item } = props;
  const { attribute, value } = item;
  const input: React.RefObject<HTMLInputElement> = createRef();
  const dispatch = useAppDispatch();
  const handleSubmit = () => {
    const { current } = input;
    if (current != null) {
      const update = { value: current.value };
      dispatch(updateReplyItem({ id: item.id, replyItem: update }));
    }
  };
  return (
    <ItemEditor
      submitHandler={handleSubmit}
      inputComponent={(
        <TextField
          inputRef={input}
          id={attribute}
          label="IP Address"
          defaultValue={value}
        />
      )}
    />
  );
};

const IpAddressFields = (props: { items: Array<ReplyItem> }) => {
  const { items } = props;

  const ips: Array<ReplyItem> = items
    .filter((item) => item.attribute === 'Framed-IP-Address');

  if (ips.length === 0) {
    return null;
  }

  return (
    <Box mb={2}>
      <Typography
        mb={2}
        variant="body1"
        component="div"
      >
        IP Addresses
      </Typography>
      {ips.map((item) => (
        <IpAddressField key={item.id} item={item} />
      ))}
    </Box>
  );
};

interface TariffsProps {
    items: Array<ReplyItem>,
}

const TariffField = ({ items }: TariffsProps) => {
  const { value } = items[0];
  const [selectValue, setSelectValue] = useState<string>(value);
  const dispatch = useAppDispatch();
  const handleChange = (e: SelectChangeEvent) => {
    setSelectValue(e.target.value);
  };
  const handleSubmit = () => {
    const update = { value: selectValue };
    items.forEach((item) => {
      dispatch(updateReplyItem({ id: item.id, replyItem: update }));
    });
  };
  return (
    <ItemEditor
      submitHandler={handleSubmit}
      inputComponent={(
        <FormControl>
          <InputLabel id="tariff-plan-label">Tariff plan</InputLabel>
          <Select
            labelId="tariff-plan-label"
            id="tariff-plan"
            label="Tariff plan"
            value={selectValue}
            onChange={handleChange}
            fullWidth
          >
            {Tariffs.map((tariff) => (
              <MenuItem
                key={tariff.id}
                value={tariff.value}
              >
                {tariff.name}

              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

const TariffFields = ({ items }: TariffsProps) => {
  // Group all the ERX- reply items into [egress, igress] tuples returning a
  // structure like [[egress,ingress][egress,ingress][egress,ingress]]
  const tariffs: Array<Array<ReplyItem>> = items
    // Only filter for ingress or egress policies
    .filter((item) => {
      if (item.attribute.startsWith('ERX-Ingress')
      || item.attribute.startsWith('ERX-Egress')) {
        return true;
      }
      return false;
    })
    // Sort them by policy speed (the value). Policies are lexically sortable.
    .sort((a, b) => a.value.localeCompare(b.value))
    // Reduce to the desired structure by looking ahead and joining pairs of
    // symmetrical policies.
    .reduce((acc, item, index, ary) => {
      const alreadyMapped: boolean = acc.some((t) => t.some((ri) => {
        if (ri.id === item.id) {
          return true;
        }
        return false;
      }));

      if (alreadyMapped) {
        return acc;
      }

      const nextIndex = index + 1;
      if (nextIndex >= ary.length) {
        return acc;
      }
      const nextItem = ary[nextIndex];
      const { value: nextValue } = nextItem;
      const { value } = item;
      if (value === nextValue) {
        const t: ReplyItem[] = [item, nextItem];
        acc.push(t);
      }
      return acc;
    }, new Array<Array<ReplyItem>>());

  if (tariffs.length < 1) {
    return null;
  }

  return (
    <Box>
      <Typography
        component="div"
        mb={2}
        variant="body1"
      >
        Tariffs
      </Typography>
      {tariffs.map((t) => (
        <TariffField key={t[0].id} items={t} />
      ))}
    </Box>
  );
};

const ReplyItems = (props: ReplyItemProps) => {
  const { replyItems } = props;
  return (
    <>
      <IpAddressFields items={replyItems} />
      <TariffFields items={replyItems} />
    </>
  );
};

export default ReplyItems;
