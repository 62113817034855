import React, { useState, useEffect } from 'react';

import { UseFormSetValue, FieldValues } from 'react-hook-form';
import { StandardTextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

const PPPoECRNs = [
  'pineapple.net.au',
  'axecom.com.au',
  'fgtelecom.com.au',
  'voiceconnectinc.com',
  'rapid-net.com.au',
  'platinum-apartments.com.au',
  'sks.com.au',
  'monoaparments.net.au',
  'corporatekeysaustralia.com.au',
  'citycable.com.au',
  'aussietel.com.au',
  'mocs.com.au',
  'yomojo.com.au',
  'voipline.net.au',
];

const IPoECRNs = [
  'fttp.dgtek.net',
];

export type AuthProtocol = 'PPPoE' | 'IPoE';

type UsernameFieldProps = StandardTextFieldProps & {
  setValue: UseFormSetValue<FieldValues>;
  authProtocol: AuthProtocol;
  name: string;
}

const UsernameField = ({
  setValue,
  authProtocol,
  name,
  error,
  helperText,
}: UsernameFieldProps) => {
  const CRNs = authProtocol === 'PPPoE' ? PPPoECRNs : IPoECRNs;
  const [components, setComponents] = useState<Array<string>>(['', '@', '']);

  useEffect(() => {
    setComponents([...components.slice(0, -1), CRNs[0]]);
  }, [authProtocol]);

  const handleSIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setComponents([e.target.value, ...components.slice(1)]);
  };

  const handleCRNChange = (e: SelectChangeEvent) => {
    e.preventDefault();
    setComponents([...components.slice(0, -1), e.target.value]);
  };

  useEffect(() => {
    setValue(name, components.join(''));
  }, [components]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'stretch',
        my: 1,
      }}
    >
      <FormControl variant="outlined">
        <InputLabel
          shrink
          sx={{ backgroundColor: 'white', padding: '0 0.25em' }}
          htmlFor="sid-input"
        >
          Username
        </InputLabel>
        <OutlinedInput
          sx={{
            minWidth: 0,
            width: 'auto',
            '& fieldset': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          onChange={handleSIDChange}
          value={components[0]}
          error={error}
          id="username-input"
          aria-describedby="username-helper-text"
        />
      </FormControl>
      <OutlinedInput
        disabled
        error={error}
        value="@"
        sx={{
          borderRadius: 0,
          width: '45px',
          bgcolor: 'grey.100',
          '& fieldset': {
            borderLeftWidth: 0,
            borderRightWidth: 0,
          },
        }}
      />
      <FormControl sx={{ flexGrow: 1 }}>
        <Select
          labelId="crn-select-input"
          id="crn-select-input"
          autoWidth
          onChange={handleCRNChange}
          value={components[2]}
          error={error}
          sx={{
            '& fieldset': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          }}
        >
          {CRNs.map((crn) => <MenuItem value={crn}>{crn}</MenuItem>)}
        </Select>
      </FormControl>
      <FormHelperText error={error} hidden={!error} id="coupon-helper-text">
        { helperText }
      </FormHelperText>
    </Box>
  );
};

export default UsernameField;
