import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Client } from '../../http';

export interface Duplicate {
  id: number;
  username: string;
  attribute: string;
  value: string;
  op: string;
}

export interface DuplicateUpdate {
  value?: string,
  op?: string,
}

export interface duplicatesState {
  value: Array<Duplicate>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: duplicatesState = {
  value: [],
  status: 'idle',
};

export const fetchDuplicates = createAsyncThunk(
  'radius/fetchDuplicates',
  async (_, { rejectWithValue }) => {
    let response;
    try {
      response = await Client
        .get('/api/v1/admin/freeradius/duplicates');
    } catch (e) {
      return rejectWithValue(e);
    }
    return response.data.duplicates.map((x: Duplicate) => x);
  },
);

export const duplicatesSlice = createSlice({
  name: 'duplicates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDuplicates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDuplicates.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchDuplicates.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectDuplicats = (state: RootState) => state.duplicates.value;
export default duplicatesSlice.reducer;
