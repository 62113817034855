import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import update from 'react-addons-update';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface UserMetadata {
  address: string;
  phone_number: string;
  user_unique_code: string;
}

export interface User {
  id: string;
  user_id: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  user_metadata: UserMetadata;
  picture: string;
  last_ip: string;
  logins_count: number;
  emails_verified: boolean;
  phone_number: string;
  address: string;
  user_unique_code: string;
}

export interface usersState {
  value: Array<User>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: usersState = {
  value: [],
  status: 'idle',
};
// 'https://backend.support.prod.dgtek.link/api/v1/admin/me',

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await Client.get('/api/v1/admin/users');
  // The value we return becomes the `fulfilled` action payload
  //
  response.data.map((x: User) => {
    x.id = x.user_id;
    return x;
  });
  return response.data;
});

export const updateUsers = createAsyncThunk<
  Array<User>,
  Array<User>,
  {
    state: RootState;
  }
>('users/updateUser', async (users: Array<User>, { getState }) => {
  const response = await Client.patch('/api/v1/admin/users/udpate', {
    data: users,
  });

  const state: RootState = getState();

  response.data.map((x: User) => {
    x.id = x.user_id;
    return x;
  });

  return update(state.users.value, response.data);
});

export const deleteUsers = createAsyncThunk<
  Array<User>,
  Array<User>,
  {
    state: RootState;
  }
>('users/deleteUsers', async (users: Array<User>, { getState }) => {
  const response = await Client.post('/api/v1/admin/users/delete', {
    data: users,
  });

  const state: RootState = getState();
  return state.users.value.filter((u: User) => !users.includes(u));
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(deleteUsers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(updateUsers.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectUsers = (state: RootState) => state.users.value;
export default usersSlice.reducer;
