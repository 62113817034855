import React, { createRef, useState } from 'react';
import { GridToolbarQuickFilterProps } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { CustomPagination } from './Pagination';

type QuickFilterProps = GridToolbarQuickFilterProps & { onSubmit: (filter: string) => void, pageSize: number}

const filterFieldLabels: Record<string, string> = {
  username: 'Usernames',
  ip_address: 'IP Addresses',
  cidr: 'CIDR notation',
};

const QuickFilter = (props: QuickFilterProps) => {
  const { onSubmit, pageSize } = props;
  const [filterField, setFilterField] = useState<string>('username');
  const input: React.RefObject<HTMLInputElement> = createRef();
  const handleFilterFieldChange = (event: SelectChangeEvent) => {
    setFilterField(event.target.value as string);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { current } = input;
    if (current === null || current.value === '') {
      onSubmit('');
      return;
    }
    onSubmit(`${filterField}=${current.value}`);
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        pt: 2, pb: 1, pl: 1, pr: 1, display: 'flex', alignItems: 'center',
      }}
    >
      <CustomPagination pageSize={pageSize} />
      <Box sx={{ ml: 'auto' }}>
        <FormControl sx={{ pr: 1 }}>
          <InputLabel id="filter-field-label">Search by</InputLabel>
          <Select
            labelId="filter-field-label"
            size="small"
            value={filterField}
            label="Search by"
            onChange={handleFilterFieldChange}
          >
            <MenuItem value="username">{filterFieldLabels.username}</MenuItem>
            <MenuItem value="ip_address">{filterFieldLabels.ip_address}</MenuItem>
            <MenuItem value="cidr">{filterFieldLabels.cidr}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          size="small"
          sx={{ pr: 1 }}
          placeholder={`Search ${filterFieldLabels[filterField].toLowerCase()}`}
          inputRef={input}
          label="Query"
          inputProps={{ 'aria-label': `search ${filterFieldLabels[filterField].toLowerCase()}` }}
        />
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default QuickFilter;
