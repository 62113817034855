import React, { useState, useEffect } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  MenuItem,
  Select,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Tariffs } from './tariff';
import { useAppDispatch } from '../../app/hooks';
import {
  SubscriberForm as Form,
  createSubscriber,
} from '../../features/radius/subscribers';
import UsernameField, { AuthProtocol } from './UsernameField';

const SubscriberForm = () => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const usernameField = register('username', {
    validate: (value) => value.split('@')[0].length > 0,
  });
  const passwordField = register('password', { required: true });
  const tariffField = register('tariff', { required: true });

  const [
    networkField,
    setNetworkField,
  ] = useState<string>('ip_address');

  const [
    authProtocolField,
    setAuthProtocolField,
  ] = useState<AuthProtocol>('PPPoE');

  const ipField = register('ip', { required: networkField !== 'cgnat' });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const creating = searchParams.get('creating');
    if (creating === 'true') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [searchParams]);

  const handleChange = (
    e: React.MouseEvent<HTMLElement>,
    newNetworkField: string,
  ) => {
    e.preventDefault();
    if (newNetworkField == null) {
      return;
    }
    setNetworkField(newNetworkField);
  };

  const handleAuthProtocolFieldChange = (
    e: React.MouseEvent<HTMLElement>,
    newAuthProtocolField: AuthProtocol,
  ) => {
    e.preventDefault();
    if (newAuthProtocolField === null) {
      return;
    }
    setAuthProtocolField(newAuthProtocolField);
  };

  const handleClose = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSearchParams('');
    reset();
  };

  const postData = async ({
    username, password, ip, tariff,
  }: FieldValues) => {
    const subscriber: Form = {
      username,
      check_items: [{
        attribute: 'Cleartext-Password',
        value: password,
        op: ':=',
      }],
      reply_items: [
        {
          attribute: networkField === 'ip_address'
            ? 'Framed-IP-Address'
            : 'Framed-Pool',
          value: networkField === 'cgnat' ? 'CGNAT-POOL' : ip,
          op: ':=',
        },
        {
          attribute: 'ERX-Ingress-Policy-Name',
          value: tariff.split(',')[1],
          op: ':=',
        },
        {
          attribute: 'ERX-Egress-Policy-Name',
          value: tariff.split(',')[0],
          op: ':=',
        },
      ],
    };

    if (networkField === 'cgnat' || ip === 'CGNAT-POOL') {
      subscriber.reply_items.push({
        attribute: 'ERX-virtual-Router-Name',
        value: 'RI-CGNAT',
        op: ':=',
      });
    }

    await dispatch(createSubscriber(subscriber));
    navigate(`/dashboard/subscribers/${subscriber.username}`);
    reset();
  };

  const handlePasswordGen = (evt: any) => {
    evt.preventDefault();
    const chars = 'abcdefghijklmnopqrstubwsyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let pw = '';
    for (let index = 0; index < 10; index += 1) {
      pw += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setValue('password', pw);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ minWidth: '400px' }}>
        <Box
          component="form"
          onSubmit={handleSubmit(
            (data: FieldValues) => postData(data),
          )}
        >
          <CardHeader
            component="div"
            title="New subscriber"
          />
          <CardContent>
            <Box sx={{ mb: 1.5 }}>
              <Typography
                variant="body1"
                component="div"
              >
                Authentication
              </Typography>
              <InputLabel
                sx={{
                  position: 'relative',
                  top: '1em',
                  ml: 1.5,
                  backgroundColor: 'white',
                  zIndex: 1,
                  display: 'inline-block',
                  padding: '0 0.4em',
                }}
                size="small"
                shrink
              >
                Protocol

              </InputLabel>
              <ToggleButtonGroup
                sx={{ mb: 0.5 }}
                exclusive
                value={authProtocolField}
                onChange={handleAuthProtocolFieldChange}
                fullWidth
              >
                <ToggleButton
                  color="primary"
                  value="PPPoE"
                >
                  PPPoE
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="IPoE"
                >
                  IPoE
                </ToggleButton>
              </ToggleButtonGroup>
              <UsernameField
                setValue={setValue}
                authProtocol={authProtocolField}
                name={usernameField.name}
                error={!!errors.username}
                helperText={
                errors.username?.message
                  ? errors.username.message.toString()
                  : ''
                }
                required
              />
              <TextField
                onChange={passwordField.onChange}
                onBlur={passwordField.onBlur}
                inputRef={passwordField.ref}
                name={passwordField.name}
                error={!!errors.password}
                InputLabelProps={{ shrink: true }}
                helperText={
                errors.password?.message
                  ? errors.password.message.toString()
                  : ''
                }
                id="password"
                label="Password"
                margin="dense"
                fullWidth
              />
            </Box>
            <Button color="success" onClick={handlePasswordGen}>Generate Password</Button>
            <Box sx={{ mb: 1.5 }}>
              <Typography
                variant="body1"
                component="div"
              >
                Network
              </Typography>
              <ToggleButtonGroup
                sx={{ mt: 1, mb: 1.5 }}
                exclusive
                value={networkField}
                onChange={handleChange}
                fullWidth
              >
                <ToggleButton
                  color="primary"
                  value="ip_address"
                >
                  IP Address
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="ip_pool"
                >
                  IP Pool
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="cgnat"
                >
                  CGNAT
                </ToggleButton>
              </ToggleButtonGroup>
              {networkField !== 'cgnat'
              && (
              <TextField
                sx={{ mt: 0 }}
                error={!!errors.ip}
                helperText={
                errors.ip?.message
                  ? errors.ip.message.toString()
                  : ''
                }
                onChange={ipField.onChange}
                onBlur={ipField.onBlur}
                inputRef={ipField.ref}
                name={ipField.name}
                id="ip_address"
                label={networkField === 'ip_address'
                  ? 'IP Address'
                  : 'IP Pool'}
                margin="normal"
                fullWidth
              />
              )}
            </Box>
            <Box>
              <Typography
                variant="body1"
                component="div"
              >
                Tariff information
              </Typography>
              <FormControl
                margin="dense"
                fullWidth
              >
                <InputLabel id="tariff-plan-label">Tariff plan</InputLabel>
                <Select
                  onChange={tariffField.onChange}
                  onBlur={tariffField.onBlur}
                  inputRef={tariffField.ref}
                  name={tariffField.name}
                  error={!!errors.tariff}
                  id="tariff-plan"
                  label="Tariff plan"
                  defaultValue={`${Tariffs[0].value},${Tariffs[0].upstream ? Tariffs[0].upstream : Tariffs[0].value}`}
                >
                  {Tariffs.map((tariff) => (
                    <MenuItem
                      key={tariff.id}
                      value={`${tariff.value},${tariff.upstream ? tariff.upstream : tariff.value}`}
                    >
                      {tariff.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </CardContent>
          <CardActions>
            <ButtonGroup fullWidth>
              <Button
                size="large"
                variant="contained"
                type="submit"
              >
                Create
              </Button>
              <Button
                size="large"
                color="error"
                variant="outlined"
                type="submit"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </CardActions>
        </Box>
      </Card>
    </Modal>
  );
};

export default SubscriberForm;
