import React, {
  useState, useEffect, createRef,
} from 'react';
import {
  Box, Typography, TextField, Button, Modal, Checkbox, CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  deleteSubscriber, searchSubscribers, selectSubscribers, Subscriber,
} from '../../features/radius/subscribers';
import { ItemEditor } from './Item';
import { RootState } from '../../app/store';
import {
  CheckItem,
  fetchCheckItems,
  updateCheckItem,
} from '../../features/radius/check-items';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

export interface CheckItemProps {
  checkItems: Array<CheckItem>;
  username: string;
}

interface PasswordProps {
  item: CheckItem;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode ;
  onClose: () => void;
}

const BootstrapDialogTitle = ({ children, onClose, ...other }: DialogTitleProps) => (
  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
);

const PasswordField = ({ item }: PasswordProps) => {
  const { attribute, value } = item;
  const dispatch = useAppDispatch();

  const input: React.RefObject<HTMLInputElement> = createRef();
  const handleSubmit = () => {
    const { current } = input;
    if (current != null) {
      const update = { value: current.value };
      dispatch(updateCheckItem({ id: item.id, checkItem: update }));
    }
  };
  return (
    <ItemEditor
      submitHandler={handleSubmit}
      inputComponent={(
        <TextField
          inputRef={input}
          id={attribute}
          label="Password"
          defaultValue={value}
        />
      )}
    />
  );
};
const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 8,
};

const CheckItems = ({ checkItems, username }: CheckItemProps) => {
  const [realmGood, setRealmGood] = useState(false);
  const [ipGood, setIpGood] = useState(false);
  const [goodToDelete, setGoodToDelete] = useState(false);
  const [subIPs, setSubIPs] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseSuccess = () => {
    setOpen(false);
    window.location.replace('/dashboard/subscribers');
  };

  const passwords: Array<CheckItem> = checkItems
    .filter((item) => item.attribute === 'Cleartext-Password');

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(searchSubscribers({ filter: `username=${username.split('@')[0]}`, pageSize: 100 }));
  }, []);

  const subs = useAppSelector((state: RootState) => state.subscribers);

  const handleCloseDelete = () => {
    if (subs?.value[0]?.username) {
      dispatch(deleteSubscriber({ username: subs?.value[0]?.username }));
    }
  };

  useEffect(() => {
    if (subs?.value?.length === 1 && subs?.value[0]?.ip_address !== '0.0.0.0') {
      setLoading(true);
      axios.get(`/api/v1/admin/freeradius/subscribers/search?filter=ip_address=${subs.value[0].ip_address}`)
        .then((res: any) => {
          setSubIPs(res.data.subscribers);

          setRealmGood(!(subs.value.length > 1 || subs.value.length === 0));

          setIpGood(!(res.data.subscribers.length > 1));
        }).catch((error) => {
          console.log('bad error', error.data);
          setIpGood(false);
        }).finally(() => setLoading(false));
    } else {
      setIpGood(true);
      setRealmGood(subs?.value?.length === 1);
    }
  }, [subs.value]);

  const handleDelete = () => {
    setGoodToDelete(ipGood && realmGood);
    setOpen(true);
  };

  const renderSubs = () => subs.value.map((sub) => (
    <p>
      🚨 extra realm or duplicate found for
      {' '}
      {sub.username}
      :
      {' '}
      <b>
        {sub.username.split('@')[1]}
      </b>
    </p>
  ));

  const renderIPs = () => subIPs.map((sub: Subscriber) => (
    <p>
      🚨 extra user with the same ip address found
      {' '}
      <b>
        {sub.ip_address}
        :
        {' '}
        {sub.username}
        {' '}

      </b>
      🚨
    </p>
  ));

  const renderPasswords = () => {
    if (passwords.length === 0) {
      return null;
    }

    return (
      <Box mb={2}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >

          { (subs?.value[0]?.username !== 'deleted') ? (
            <>
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Deletion precheck
              </BootstrapDialogTitle>
              <DialogContent dividers>
                { (subs.status === 'failed')
                  ? (
                    <Typography gutterBottom>
                      Unfortunately an error has occurred, please contact administrator.
                    </Typography>
                  ) : (
                    <>
                      <Typography gutterBottom>
                        {realmGood ? '✅ no additional realm found' : renderSubs()}
                      </Typography>
                      <Typography gutterBottom>
                        {ipGood ? '✅ no additional subscribers with the ip address found' : renderIPs() }
                      </Typography>
                    </>
                  )}
              </DialogContent>
              <DialogActions>
                <Button autoFocus color="error" disabled={(!goodToDelete && !loading) || subs.status === 'failed'} variant="outlined" onClick={handleCloseDelete}>
                  Proceed to delete
                </Button>
              </DialogActions>

            </>
          )
            : (
              <>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                  Deletion result
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom>
                    ✅ deleted successfully
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus color="error" variant="outlined" onClick={handleCloseSuccess}>
                    Close
                  </Button>
                </DialogActions>
              </>
            )}
        </BootstrapDialog>
        <Typography
          variant="body1"
          mb={2}
          component="div"
        >
          Authentication
        </Typography>
        {passwords.map((password) => (
          <PasswordField key={password.id} item={password} />
        ))}

        <Button onClick={handleDelete} variant="outlined" color="error">Delete</Button>
      </Box>
    );
  };

  return renderPasswords();
};

export default CheckItems;
