import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface ReplyItem {
  id: number;
  username: string;
  attribute: string;
  value: string;
  op: string;
}

export interface ReplyItemUpdate {
  value?: string;
  op?: string;
}

export interface replyItemsState {
  value: Array<ReplyItem>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: replyItemsState = {
  value: [],
  status: 'idle',
};

export const fetchReplyItems = createAsyncThunk(
  'radius/fetchReplyItems',
  async ({ username }: {username: string}, { rejectWithValue }) => {
    const filter = `filter=username=${username}`;
    let response;
    try {
      response = await Client
        .get(`/api/v1/admin/freeradius/reply_items/search?${filter}`);
    } catch (e) {
      return rejectWithValue(e);
    }
    response.data.items.map((x: ReplyItem) => x);
    return response.data.items;
  },
);

export const updateReplyItem = createAsyncThunk(
  'radius/updateReplyItem',
  async (
    { id, replyItem }: { id: number, replyItem: ReplyItemUpdate },
    { getState, rejectWithValue },
  ) => {
    let response;
    try {
      response = await Client
        .patch(`/api/v1/admin/freeradius/reply_items/${id}`, {
          item: replyItem,
        });
    } catch (e) {
      return rejectWithValue(e);
    }

    const { replyItems } = getState() as { replyItems: Array<ReplyItem> };
    const updatedItem = response.data.item;

    return replyItems.map((item) => {
      if (item.id === updatedItem.id) {
        return updatedItem;
      }
      return item;
    });
  },
);

export const replyItemsSlice = createSlice({
  name: 'replyItems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReplyItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReplyItems.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchReplyItems.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default replyItemsSlice.reducer;
