import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Subscriber {
  username: string;
  password: string;
  ip_address: string;
  ip_pool: string;
  speed: string;
  total: number;
}

interface SubscriberResponse {
  subscriber: Subscriber;
}

interface SubscriberListResponse {
  subscribers: Array<Subscriber>;
  next_page_token: string;
  previous_page_token: string;
}

export interface SubscriberForm {
  username: string;
  check_items: Array<ItemForm>;
  reply_items: Array<ItemForm>;
}

export interface ItemForm {
  attribute: string;
  value: string;
  op: string;
}

export interface subscribersState {
  value: Array<Subscriber>;
  nextPageToken: string;
  previousPageToken: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: subscribersState = {
  value: [],
  nextPageToken: '',
  previousPageToken: '',
  status: 'idle',
};

export const fetchSubscribers = createAsyncThunk<
  SubscriberListResponse,
  string | undefined
>('radius/fetchSubscribers', async (pageToken?: string) => {
  const pt = pageToken ? `&page_token=${pageToken}` : '';
  const response = await Client.get<SubscriberListResponse>(
    `/api/v1/admin/freeradius/subscribers?${pt}`,
  );
  return response.data;
});

interface SubscriberSearchParams {
  filter: string;
  pageToken?: string;
  pageSize: number;
}

interface SubscriberDeleteParams {
  username: string;
}

export const searchSubscribers = createAsyncThunk<
  SubscriberListResponse,
  SubscriberSearchParams
>(
  'radius/searchSubscribers',
  async ({ filter, pageToken, pageSize }: SubscriberSearchParams) => {
    const f = filter !== null ? `filter=${filter}` : '';
    const pt = pageToken ? `&page_token=${pageToken}` : '';
    const ps = pageSize ? `&page_size=${pageSize}` : '';
    const response = await Client.get<SubscriberListResponse>(
      `/api/v1/admin/freeradius/subscribers/search?${f}${pt}${ps}`,
    );
    return response.data;
  },
);

export const deleteSubscriber = createAsyncThunk<
  SubscriberListResponse,
  SubscriberDeleteParams
>(
  'radius/deleteSubscriber',
  async ({ username }: SubscriberDeleteParams) => {
    const response = await Client.delete<SubscriberListResponse>(
      `/api/v1/admin/freeradius/subscribers/?username=${username}`,
    );
    return response.data;
  },
);

export const createSubscriber = createAsyncThunk<
  Subscriber,
  SubscriberForm,
  {state: RootState}
>('radius/createSubscriber', async (subscriber: SubscriberForm) => {
  const response = await Client.post<SubscriberResponse>(
    '/api/v1/admin/freeradius/subscribers',
    { subscriber },
  );
  return response.data.subscriber;
});

export const subscribersSlice = createSlice({
  name: 'subscribers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscribers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscribers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.subscribers;
        state.previousPageToken = action.payload.previous_page_token;
        state.nextPageToken = action.payload.next_page_token;
      })
      .addCase(fetchSubscribers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(searchSubscribers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchSubscribers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.subscribers;
        state.previousPageToken = action.payload.previous_page_token;
        state.nextPageToken = action.payload.next_page_token;
      })
      .addCase(searchSubscribers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteSubscriber.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteSubscriber.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSubscriber.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [{ username: 'deleted' } as Subscriber];
      })
      .addCase(createSubscriber.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSubscriber.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push(action.payload);
      })
      .addCase(createSubscriber.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectSubscribers = (state: RootState) => state.subscribers.value;
export default subscribersSlice.reducer;
