import React from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

const columns: GridColDef[] = [
  { field: 'kind', headerName: 'kind', width: 130 },
  { field: 'value', headerName: 'value', width: 130 },
  { field: 'collection', headerName: 'collection', width: 130 },
  { field: 'identifiers', headerName: 'identifiers', width: 130 },
  { field: 'count', headerName: 'count', width: 130 },
];

const Duplicates = () => {
  const duplicates = useAppSelector(
    (state: RootState) => state.duplicates,
  );

  return (
    <DataGridPro
      autoHeight
      rows={duplicates.value}
      columns={columns}
      pageSize={20}
      rowsPerPageOptions={[10, 20, 30, 100]}
      pagination
      checkboxSelection
      getRowId={(row) => row.value}
    />
  );
};

export default Duplicates;
