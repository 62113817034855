import React, { useState } from 'react';
import {
  Box, IconButton, ButtonGroup,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

export interface ItemEditorProps {
  submitHandler: () => void;
  inputComponent: JSX.Element;
}

export const ItemEditor = ({
  submitHandler,
  inputComponent,
}:ItemEditorProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const toggleEditing = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setEditing(!editing);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitHandler();
    setEditing(false);
  };

  const input = React.cloneElement(
    inputComponent,
    { disabled: !editing },
  );

  return (
    <Box
      sx={{ mb: 2, display: 'flex', alignItems: 'center' }}
      component="form"
      onSubmit={handleSubmit}
    >
      { input }
      <ButtonGroup sx={{ ml: 1 }} variant="outlined" size="small">
        { !editing
          ? (
            <IconButton size="small" onClick={toggleEditing}>
              <EditIcon fontSize="inherit" />
            </IconButton>
          )
          : (
            <IconButton size="small" type="submit">
              <SaveIcon fontSize="inherit" />
            </IconButton>
          )}
      </ButtonGroup>
    </Box>
  );
};
