import * as React from 'react';
import Box from '@mui/material/Box';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

interface BreadcrumbsProps {
  username: string | undefined;
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { username } = props;

  const usernameLink = username !== undefined
    ? (
      <Link
        component={RouterLink}
        to={`/dashboard/subscribers/${username}`}
      >
        {username}
      </Link>
    ) : null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pb: 3 }}>
      <MaterialBreadcrumbs maxItems={2}>
        <Link component={RouterLink} to="/dashboard/subscribers">Subscribers</Link>
        {usernameLink}
      </MaterialBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
