import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import MicrosoftLogin from 'react-microsoft-login';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useAppDispatch } from '../app/hooks';
import { fetchMe, setToken } from '../features/me/me';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_, setCookie] = useCookies(['jolly']);

  return (
    <div style={{ flexGrow: '1' }}>
      <Box flex={1} sx={{ width: '100%' }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={4}>
            <img width={128} src="logo.png" alt="logo" />
          </Grid>
          <Grid item xs={4}>
            <h2>
              DGtek Admin Portal
            </h2>
          </Grid>
          <Grid item xs={4} alignContent="center" />
          <Grid item xs={4} alignContent="center">
            <MicrosoftLogin
              clientId="35c218ef-227d-40fe-aac6-70ff4e50191c"
              tenantUrl="https://login.microsoftonline.com/dgtek.net"
              authCallback={(err, data) => {
                dispatch(setToken(data.idToken.rawIdToken));
                dispatch(fetchMe(data.idToken.rawIdToken));
                setCookie('jolly', data.idToken.rawIdToken, {
                  path: '/',
                  secure: true,
                  sameSite: 'none',
                });
                navigate('/dashboard/subscribers');
              }}
            />
          </Grid>
          <Grid item>2022 © DGtek Pty. Ltd. ABN 61 600 896 115</Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Login;
