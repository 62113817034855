import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import ReportIcon from '@mui/icons-material/Report';
import { Link } from 'react-router-dom';
import { RootState } from '../../app/store';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchDuplicates } from '../../features/radius/duplicates';

const DuplicatesListItem = () => {
  const duplicatesState = useAppSelector(
    (state: RootState) => state.duplicates,
  );
  const dispatch = useAppDispatch();
  const [duplicates, setDuplicates] = useState(duplicatesState.value);

  useEffect(() => {
    setDuplicates(duplicatesState.value);
  }, [duplicatesState.value]);

  useEffect(() => {
    dispatch(fetchDuplicates());
  }, []);

  if (duplicates.length === 0) {
    return null;
  }

  return (
    <ListItem>
      <ListItemButton
        component={Link}
        to="/dashboard/subscribers/duplicates"
      >
        <ListItemIcon>
          <Badge badgeContent={duplicates.length} color="error">
            <ReportIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Resolve duplicate items" />
      </ListItemButton>
    </ListItem>
  );
};

export default DuplicatesListItem;
