import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {
  GridColumns,
  DataGridPro,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import { useFilter } from './SubscribersPage';
import QuickFilter from './QuickFilter';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  searchSubscribers, Subscriber,
} from '../../features/radius/subscribers';
import { RootState } from '../../app/store';
import { CustomPagination } from './Pagination';
import CopyToClipboardButton from '../CopyToClipBoard';

const SubscriberTable = () => {
  const { filter: urlFilter } = useFilter();
  const { history, location } = window;
  const subscribersState = useAppSelector(
    (state: RootState) => state.subscribers,
  );
  const dispatch = useAppDispatch();
  const [subscribers, setSubscribers] = useState(subscribersState.value);
  const [previousPageToken, setPreviousPageToken] = useState<string>('');
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [currentFilter, setCurrentFilter] = useState<string>(urlFilter);
  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(pageSize);
  const [rowCountState, setRowCountState] = useState(rowCount);

  useEffect(() => {
    setRowCount(10000);
  }, []);

  useEffect(() => {
    setRowCountState(
      (prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState),
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => setSubscribers(subscribersState.value), [subscribersState.value]);
  useEffect(() => setNextPageToken(
    subscribersState.nextPageToken,
  ), [subscribersState.nextPageToken]);
  useEffect(() => setPreviousPageToken(
    subscribersState.previousPageToken,
  ), [subscribersState.previousPageToken]);

  // load data initially
  useEffect(() => {
    dispatch(searchSubscribers({ filter: urlFilter, pageSize }));
  }, []);

  useEffect(() => {
    setCurrentFilter(urlFilter);
    dispatch(searchSubscribers({ filter: urlFilter, pageSize }));
  }, [urlFilter]);

  const search = (filter: string) => {
    setCurrentFilter(filter);
    const url = new URL(location.href);
    if (filter !== '') {
      url.searchParams.set('filter', filter);
    } else {
      url.searchParams.delete('filter');
    }
    history.pushState({}, '', url);
    dispatch(searchSubscribers({ filter, pageSize }));
  };

  const fetchNextPage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(searchSubscribers({ filter: currentFilter, pageToken: `${page}`, pageSize }));
  };

  const fetchPreviousPage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(searchSubscribers({ filter: currentFilter, pageToken: `${page}`, pageSize }));
  };

  const fetchPage = (pageNum: number) => {
    dispatch(searchSubscribers({ filter: currentFilter, pageToken: `${pageNum}`, pageSize }));
  };

  const next = nextPageToken === '' ? null : (
    <Button onClick={fetchNextPage}>
      <ArrowForward />
    </Button>
  );
  const previous = previousPageToken === ''
    ? null : (
      <Button onClick={fetchPreviousPage}>
        <ArrowBack />
      </Button>
    );

  const controls = () => (
    <ButtonGroup>
      {previous}
      {next}
    </ButtonGroup>
  );

  const columns: GridColumns<Subscriber> = [
    {
      field: 'username',
      headerName: 'Username',
      editable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <div style={{ float: 'left' }}>
            <Link component={RouterLink} to={params.value || ''}>
              {params.value}
            </Link>
          </div>
          <div style={{ float: 'right' }}>
            {' '}
            <CopyToClipboardButton textToCopy={params.value!} />
          </div>
        </div>

      ),
    },
    {
      field: 'password',
      headerName: 'Password',
      editable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <div style={{ float: 'left' }}>
            ******
          </div>
          <div style={{ float: 'right' }}>
            <CopyToClipboardButton textToCopy={params.value!} />
          </div>
        </div>
      ),
    },
    {
      field: 'ip_address',
      headerName: 'IP Address',
      editable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <div style={{ float: 'left' }}>
            {params.value}
          </div>
          <div style={{ float: 'right' }}>
            <CopyToClipboardButton textToCopy={params.value!} />
          </div>
        </div>
      ),
    },
    {
      field: 'ip_pool',
      headerName: 'IP Pool',
      editable: false,
      flex: 1,
    },
    {
      field: 'speed',
      headerName: 'Speed',
      editable: false,
      flex: 1,
    },
    {
      type: 'actions',
      field: 'actions',
      editable: false,
      flex: 1,
      align: 'right',
      getActions: (params: GridRowParams<Subscriber>) => [
        <Button
          size="small"
          variant="outlined"
          key={params.row.username}
          to={params.row.username}
          component={RouterLink}
        >
          View

        </Button>,
      ],
    },
  ];

  return (
    <DataGridPro
      rowCount={subscribers.length > 0 ? subscribers[0].total : rowCount}
      autoHeight
      disableColumnFilter
      disableColumnSelector
      loading={subscribersState.status === 'loading'}
      rows={subscribers}
      columns={columns}
      paginationMode="server"
      page={page}
      onPageChange={(newPage) => {
        fetchPage(newPage);
        setPage(newPage);
      }}
      initialState={{
        pagination: {
          pageSize,
        },
        sorting: {
          sortModel: [{ field: 'ip_address', sort: 'asc' }],
        },
      }}
      getRowId={(row) => row.username}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 50, 100, 500, 1000, 10000]}
      pageSize={pageSize}
      pagination
      filterMode="server"
      components={{
        Pagination: CustomPagination,
        Toolbar: QuickFilter,
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        pagination: {
          pageSize,
        },
        toolbar: {
          pageSize,
          onSubmit: search,
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );
};

export default SubscriberTable;
