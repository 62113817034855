import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchUsers, deleteUsers, updateUsers } from '../features/users/users';
import '../App.css';
import { RootState } from '../app/store';

const SupportUsers = () => {
  const usersState = useAppSelector((state: RootState) => state.users);
  const dispatch = useAppDispatch();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [rows, setRows] = React.useState(usersState.value);

  useEffect(() => setRows(usersState.value), [usersState.value]);

  useEffect(
    () => {
      dispatch(fetchUsers());
      setRows(usersState.value);
    },
    [],
  );

  type Row = typeof usersState.value[0];

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
    const r = rows.filter((row) => row.id === id);
    dispatch(deleteUsers(r));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: GridRowModel<Row>) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
    dispatch(updateUsers([newRow]));
    return updatedRow;
  };
  const columns: GridColDef<Row>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'picture',
      headerName: 'Picture',
      editable: false,
      renderCell: (
        params,
      ) => (
        <img
          width={50}
          alt="alt image1"
          src={params.value}
        />
      ), // renderCell will render the component
    },
    {
      field: 'address_code',
      headerName: 'Address Code',
      width: 200,
      editable: true,
      valueGetter: (params) => params?.row?.user_metadata?.user_unique_code,
    },
    {
      field: 'given_name',
      headerName: 'First name',
      editable: true,
    },
    {
      field: 'family_name',
      headerName: 'Last name',
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: true,
    },
    {
      field: 'email_verified',
      headerName: 'Email Verified',
      type: 'boolean',
      editable: false,
    },
    {
      field: 'address',
      width: 300,
      headerName: 'Address',
      editable: true,
      valueGetter: (params) => params?.row?.user_metadata?.address,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      editable: true,
      valueGetter: (params) => params?.row?.user_metadata?.phone_number,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [<GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(params.row.id)}
          />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.row.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.row.id)}
          />,
        ];
      },
    },

  ];

  return (
    <Box sx={{ height: 800 }}>
      <Toolbar />
      <DataGrid
        autoHeight
        loading={usersState.status !== 'idle' && usersState.status !== 'failed'}
        rows={rows}
        columns={columns}
        pageSizeOptions={[100]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
};

export default SupportUsers;
