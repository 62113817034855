import * as React from 'react';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridRowCountSelector,
} from '@mui/x-data-grid-pro';
import Pagination from '@mui/material/Pagination';
import {
  Box,
  FormControl, Input, InputLabel, MenuItem, Select,
} from '@mui/material';

export interface CustomPaginationProps {
  pageSize: number
}
export const CustomPagination = ({ pageSize }: CustomPaginationProps) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <>
      <Box sx={{ mr: '5' }}>
        <FormControl sx={{ ml: 'auto', width: '100px' }}>
          <InputLabel id="filter-field-label">Results on page</InputLabel>
          <Select
            labelId="filter-field-label"
            size="small"
            value={pageSize}
            label="Results on Page"
            onChange={(event) => apiRef.current.setPageSize(Number(event.target.value))}
          >
            <MenuItem value="50">{50}</MenuItem>
            <MenuItem value="100">{100}</MenuItem>
            <MenuItem value="200">{200}</MenuItem>
            <MenuItem value="300">{300}</MenuItem>
            <MenuItem value="500">{500}</MenuItem>
            <MenuItem value="800">{800}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mr: '5' }}>
        <Pagination
          showLastButton
          showFirstButton
          color="primary"
          count={pageCount}
          page={page}
          onChange={(event, value) => apiRef.current.setPage(value)}
        />
      </Box>
    </>
  );
};
